@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply overscroll-y-none border-none shadow-none outline-none;
  font-family: 'Pretendard Variable', 'Noto Sans KR', sans-serif;
    background-color: #0d0d0d;
}

:root {
    --min-size: 130px;
    --preferred-size: 25vw;
    --max-size: 300px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #0d0d0d inset;
  -webkit-text-fill-color: white;
}


.custom-scrollbar::-webkit-scrollbar {
  width:10px;
  padding: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #0d0d0d;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
  min-height: 30px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.spline {
    width: clamp(var(--min-size), var(--preferred-size), var(--max-size)) !important;
    height: clamp(var(--min-size), var(--preferred-size), var(--max-size)) !important;
}
